import React, { useState } from "react"
import classNames from "classnames"

const Icon = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      className="fill-current"
      width="30"
      height="30"
      viewBox="0 0 70 50"
    >
      <rect width="70" height="10" rx="4"></rect>
      <rect width="70" height="10" y="20" rx="4"></rect>
      <rect width="70" height="10" y="40" rx="4"></rect>
    </svg>
  )
}

function Hamburger() {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(prev => !prev)

  return (
    <div className="relative" onClick={() => console.log("What about here???")}>
      <Icon
        onClick={() => {
          console.log("Are we getting here?")
          toggleMenu()
        }}
      />
      <div
        className={classNames(
          "absolute top-40 right-0 bg-gray-700 p-4 shadow-xl",
          { hidden: !menuOpen }
        )}
      >
        <ul>
          <li>Home</li>
          <li>About</li>
          <li>Blog</li>
          <li>Projects</li>
        </ul>
      </div>
    </div>
  )
}

export default Hamburger
