import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Hamburger from "./Hamburger"

const Header = ({ siteTitle }) => {
  return (
    <header className="bg-gray-700 text-gray-100">
      <div className="px-4 py-2 flex items-center justify-between">
        <div>
          <span className="text-2xl">
            <Link to="/" className="no-underline text-current">
              <span className="">Josh Branchaud</span>
            </Link>
          </span>
        </div>
        <div className="hidden">
          <Hamburger />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
