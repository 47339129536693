/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Twitter from "./social/twitter"
import Youtube from "./social/youtube"
import Github from "./social/github"
import DevDotTo from "./social/devDotTo"
// import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="anitaliased text-gray-900">
      <div className="flex flex-col justify-between min-h-screen">
        <div>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div className="bg-white p-8 justify-center">
            <main>{children}</main>
          </div>
        </div>
        <footer className="bg-gray-200 py-2 px-4">
          <div className="flex flex-wrap items-center justify-center sm:justify-between">
            <div className="flex space-x-2 w-full sm:w-auto justify-center">
              <a href="https://twitter/com/jbrancha" className="text-gray-600">
                <Twitter />
              </a>
              <a
                href="https://www.youtube.com/channel/UCa0tJguRNbU6I8OO3nUvXxQ"
                className="text-gray-600"
              >
                <Youtube />
              </a>
              <a href="https://dev.to/jbranchaud" className="text-gray-600">
                <DevDotTo />
              </a>
              <a href="https://github.com/jbranchaud" className="text-gray-600">
                <Github />
              </a>
            </div>
            <div className="w-full sm:w-auto text-center mt-2 sm:mt-0">
              © {new Date().getFullYear()} Josh Branchaud, Built with
              {` `} <a href="https://www.gatsbyjs.org">Gatsby</a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
