import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ConvertKitForm from "../components/convertKitForm"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <div className="blog-post-container min-w-full flex flex-col items-center">
        <div className="blog-post max-w-2xl">
          <h1 className="text-2xl sm:text-3xl md:text-4xl leading-snug">
            {frontmatter.title}
          </h1>
          <div
            className="blog-post-content mt-8 space-y-8 text-lg"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <div className="mt-8 flex flex-col items-left space-y-2">
            <h2>Jump into this flywheel!</h2>
            <p>
              Get insights from me about building for the web and working for a
              better world by joining my newsletter.
            </p>
            <ConvertKitForm />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
